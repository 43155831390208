.coming-soon {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
}

.building-message-container {
    display: flex;
    justify-content: start;
}

@media only screen and (max-width: 768px) {
    .building-message-container {
        justify-content: center;
        margin-top: 15px;
    }
}