@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.typewriter-text:after {
    content: "|";
    animation: blink 0.6s infinite;
}