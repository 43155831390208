.contact-us-wrapper {
    border-radius: 0.625rem;
    /* background-color: #282c34; */
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    /* color: white; */
    margin: 10px;
    position: relative;
}

.rounded-2 {
    border-radius: 0.625rem;
}

.email-link-container {
    position: absolute;
    bottom: 10px;
    font-size: calc(3vmin);
}

.email-link-container a {
    color: #000;
    transition: 0.3s;
}

.email-link-container a:hover {
    background-color: #000;
    color: #fff;
}