body {
  margin: 0;
  font-family: 'Roobert', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.btn {
  font-family: 'Roobert', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  display: block;
  white-space: pre-wrap;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

html {
  scroll-behavior: smooth;
}

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-black:focus, .btn-black.focus {
  color: #fff;
  background-color: #333;
  border-color: #333;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-black.disabled, .btn-black:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
.show>.btn-black.dropdown-toggle {
  color: #fff;
  background-color: #666;
  border-color: #666;
}

.btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
.show>.btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}